<template>
  <div>
    <section class="main services">
      <div class="hero parallax"></div>

      <h2>{{ currentRouteName }}</h2>

      <article>
        <div class="thumb"></div>
        <p class="intro">
          In this day and age, people are aware of various security risks and potential dangers of being away from home. Staying in and/or visiting hospitality establishments, such as hotels, motels, inns, resorts, serviced apartments, shopping malls (retail), restaurants, pubs and bars, nightclubs, your clients and staff should always feel as secure and protected as they would in their own homes. This is where we put our best efforts to establish a desired atmosphere of safety.<br /><br />

          GammaTeam Security provides security services and patrolling in both interior and exterior premises monitoring and detecting suspicious behaviors and dealing with difficult guests. Our team specializes in: security strategy planning, workplace safety, risk assessments, crisis management, emergency procedures, standard operating procedures, loss prevention procedures.<br /><br />

          We work closely with our potential and existing clients to identify a level of security required at their place of business and create a customized security plan. We never rely on suggestions but focus on facts that build an entire picture and allow us to be ahead of any potentially dangerous situation
        </p>
      </article>

      <call-to-action :link_back=true></call-to-action>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HospitalitySecurity'
}
</script>

<style scoped>
section.main article div.thumb {
  background-image: url("/images/services/hospitality.jpg");
}
</style>
